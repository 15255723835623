<template>
  <div class="container">
    <div class="title mb-5 font-weight-bold">Deskripsi SIKEPO</div>
    <p class="body-2">
      SIKEPO merupakan aplikasi presensi non aparatur sipil negara pada Dinas Pertanian Provinsi Banten terkhusus Petugas Pengendalian Organisme Pengganggu Tumbuhan (POPT) sebagai pengukur kinerja harian.
      Kebijakan Privasi Aplikasi SIKEPO
    </p>
    <p class="body-2">
      Aplikasi Sadalah aplikasi android milik Pemerintah Provinsi Banten yang menangani tata kelola Pemerintah Provinsi Banten serta dikelola oleh Dinas Pertanian Provinsi Banten. Kami menghargai privasi data dan informasi pribadi anda, selaku pengguna dan pelanggan Layanan, serta berkomitmen untuk melindungi data dan informasi Anda. Kebijakan privasi ini menjelaskan bagaimana kami mengumpulkan, mengolah, menggunakan dan mengungkapkan data dan informasi Anda (Kebijakan Privasi).
    </p>

    <div class="title mb-5 mt-10 font-weight-bold">Pengakuan dan Persetujuan Anda</div>
    <p class="body-2">
      Anda wajib membaca Kebijakan Privasi ini sebelum mendaftarkan diri dan menggunakan Layanan. Dengan mendaftarkan diri dan menggunakan setiap Layanan kami, Anda menyatakan bahwa Anda telah membaca, memahami dan setuju terhadap ketentuan-ketentuan Kebijakan Privasi ini. Pengakuan dan persetujuan Anda terhadap Kebijakan Privasi ini juga berkaitan dengan ketentuan yang tercantum berdasarkan Ketentuan Penggunaan yang dianggap sebagai satu kesatuan dan tidak dapat dipisahkan.
    </p>
    <div class="title mb-5 mt-10 font-weight-bold">Pengumpulan Informasi Pengguna Layanan</div>
    <p class="body-2">
      Kami berhak mengumpulkan dan mengelola informasi Pelanggan dengan bekerja sama dengan Organisasi yang pengelolaan data pegawai di Pemerintah Provinsi Banten, pada saat pengguna layanan menyediakan informasi sebagai bagian dari proses verifikasi identitas, pada saat penggunaan Layanan, dan data lainnya; Jenis informasi yang kami kumpulkan dan kelola ketika berinteraksi dengan Anda sehubungan dengan Layanan, yakni mencakup:

    <div class="body-2">
      <ol>
        <li>Identitas pribadi mencakup: nama, NIP, NIK, jenis kelamin, nomor handphone, password yang anda gunakan untuk mengakses Aplikasi setelah pendaftaran, dan alamat surat elektronik (email);</li>
        <li>
          Data dan informasi apapun yang diunggah oleh Anda dalam penggunaan layanan. (seluruh jenis informasi diatas selanjutnya disebut sebagai 'Informasi Pengguna Layanan') Kami berhak, dari waktu ke waktu, melakukan verifikasi terhadap Informasi Pelanggan atau informasi lainnya yang Anda berikan kepada kami, dengan mengirimkan surat verifikasi, email, atau mengharuskan Anda untuk mengirimkan dokumentasi pendukung, atau cara lain apapun, sebagaimana yang diminta oleh Kami.
        </li>
      </ol>
    </div>
    </p>
    <div class="title mb-5 mt-10 font-weight-bold">Penggunaan Informasi Pengguna Layanan</div>
    <div class="body-2">
      Informasi Pelanggan dan informasi lainnya yang Anda berikan untuk penggunaan layanan, termasuk informasi tambahan yang selanjutnya Anda berikan, dapat digunakan dan diolah oleh kami untuk tujuan berikut:
      <ul class="mt-5">
        <li>
          Membantu kami dalam menyediakan, menjaga, dan meningkatkan Layanan;
        </li>
        <li>
          Mengirimkan informasi dan mendukung permintaan Kami, termasuk tanda terima, pengingat, dan pesan dukungan serta pesan administrasi;
        </li>
        <li>
          Mengirimkan kepada Anda, berita dan informasi tentang Layanan serta mengkomunikasikan kepada Anda mengenai informasi terbaru dari layanan Kami;
        </li>
        <li>
          Memberikan beberapa pilihan mengenai kegunaan informasi kami yang sesuai dengan Anda;
        </li>
        <li>
          Memberikan penjelasan yang terbuka dan jelas mengenai bagaimana kami menggunakan informasi tersebut;
        </li>
        <li>
          Mempublikasikan atau membagikan informasi yang telah dikombinasi untuk beberapa layanan, namun tentu saja dengan cara tidak akan membiarkan Anda teridentifikasi;
        </li>
        <li>
          Mengagregasikan data akun Anda, yang sudah diunggah sehingga Anda tidak dapat diidentifikasi, dengan data milik pengguna lain Layanan untuk meningkatkan kualitas Layanan;
        </li>
        <li>
          Untuk memperoleh dan mengumpulkan Informasi Pengguna Layanan, serta menyimpan Informasi Pengguna Layanan dalam suatu sistem elektronik yang dimiliki oleh Kami (Pemerintah Provinsi Banten);
        </li>
        <li>
          Menyelidiki dan mencegah penipuan atau aktivitas ilegal lainnya;
        </li>
        <li>
          Melakukan kegiatan internal, termasuk investigasi internal, kepatuhan, audit dan keperluan keamanan internal lainnya; dan
        </li>
        <li>
          Tujuan lainnya yang akan diungkapkan kepada Anda sehubungan dengan Layanan.
        </li>
      </ul>
    </div>

    <div class="title mb-5 mt-10 font-weight-bold">Keamanan Informasi Pengguna Layanan</div>
    <div class="body-2">
      <p>
        Kami bekerja keras dan berusaha sebaik mungkin untuk menjaga keamanan Informasi serta melindungi informasi Pengguna Layanan yang Anda berikan dari kehilangan, penyalahgunaan, dan pengungkapan atau akses yang tidak berwenang. Kami mengikuti langkah-langkah yang diperlukan untuk mempertahankan privasi dan keamanan dari seluruh Informasi Pengguna Layanan. Dalam hal terjadi peretasan yang berada di luar kendali kami, Anda setuju untuk membebaskan Kami dari seluruh klaim, tanggung jawab hukum ataupun pengeluaran apapun yang muncul dari peretasan tersebut.
      </p>
    </div>

    <div class="title mb-5 mt-10 font-weight-bold">Penyimpanan Informasi Pelanggan</div>
    <div class="body-2">
      <p>
        Informasi Pengguna Layanan hanya akan disimpan selama diperlukan untuk memenuhi tujuan dari pengumpulannya, atau selama penyimpanan tersebut diperlukan atau diperbolehkan oleh peraturan perundang-undangan yang berlaku. Kami akan berhenti menyimpan, atau menghapus informasi Pengguna Layanan segera setelah dianggap bahwa tujuan pengumpulan Informasi Pengguna Layanan tidak lagi dibutuhkan untuk tujuan suatu layanan atau secara hukum.
      </p>
    </div>

    <div class="title mb-5 mt-10 font-weight-bold">Perubahan</div>
    <div class="body-2">
      <p>
        Kebijakan Privasi ini dapat diubah setiap saat dari waktu ke waktu dan akan berlaku pada tanggal yang ditentukan oleh kami. Setiap perubahan atas Kebijakan Privasi ini akan dipublikasikan di Aplikasi atau media lainnya yang dianggap perlu oleh kami. Mohon baca dan telusuri Kebijakan Privasi di Situs dan/atau Aplikasi secara berkala untuk melihat perubahan-perubahan tersebut. Dengan terus menggunakan Layanan kami setelah publikasi tersebut atau pemberitahuan dari kami, Anda dianggap setuju terhadap perubahan terhadap Kebijakan Privasi ini.
      </p>
    </div>

    <div class="title mb-5 mt-10 font-weight-bold">Cara Menghubungi Kami</div>
    <div class="body-2">
      <p>
        Anda dapat menghubungi Kami melalui surat elektronik (email) ke distan@bantenprov.go.id.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "kebijakan-privasi",
  components: {},
  data: () => ({}),
};
</script>
    
