<template>
  <div>
    <video-banner />
  </div>
</template>
  
  <script>
import { mapState, mapActions } from "vuex";
import VideoBanner from "../../components/videobaner/Index.vue";

export default {
  name: "home",
  components: {
    VideoBanner,
  },
  data: () => ({}),
  mounted() {
    this.setPage({
      name: "home",
      crud: false,
    });
  },
  computed: {
    ...mapState(["device", "theme", "info", "snackbar", "loading", "page"]),
  },
  methods: {
    ...mapActions(["setPage", "getAppInfo", "snackbarClose"]),
  },
};
</script>
  
  
  
  
  