<template>
  <login-component />
</template>
<script>
import LoginComponent from "../../components/login/Index.vue";
export default {
  name: "Login",
  components: {
    LoginComponent,
  },
  data: () => ({}),
};
</script>
  
  <style>
</style>