<template>
  <div class="home pa-6">

    <v-row class="pa-1">
      <v-spacer></v-spacer>
      <v-col :cols="device.desktop ? `6` : `12`">
        <v-card>
          <v-card-title :class="`white--text ` + theme.color + ` `">Laporan Presensi Bulanan</v-card-title>
          <v-card-text>
            <v-col class="mt-6">
              <v-text-field
                label="Bulan"
                outlined
                dense
                hide-details
                type="month"
                v-model="record.bulan"
                :color="theme.color"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Pegawai"
                outlined
                dense
                hide-details
                :color="theme.color"
                v-model="record.pegawai_uuid"
                :items="pegawais"
              ></v-select>
            </v-col>
          </v-card-text>
          <v-card-actions class="mt-5">
            <v-col class="text-right">
              <v-btn
                outlined
                :color="theme.color"
                @click="printReport"
              ><v-icon
                  :color="theme.color"
                  class="mr-2"
                >mdi-printer</v-icon> CETAK</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>
    
    <script>
import { mapActions, mapState } from "vuex";
import jsPDF from "jspdf";

export default {
  name: "perusahaan-index",
  data: () => ({
    foto: "/",
    pegawais: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
      "record",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl:
        "api/v2/master-data/pegawai-shift/" + this.$route.params.pegawai_uuid,
      pagination: false,
      key: "id",
      title: "Laporan Presensi Bulanan",
      subtitle: "Berikut Daftar Seluruh Laporan Presensi Bulanan Yang Tersedia",
      breadcrumbs: [
        {
          text: "Laporan",
          disabled: true,
          href: "",
        },
        {
          text: "Presensi Bulanan",
          disabled: true,
          href: "",
        },
      ],
      showtable: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        print: false,
        export: false,
        help: false,
      },
    });
  },
  mounted() {
    this.fethcPegawais();
  },
  methods: {
    ...mapActions(["setPage", "assignFileBrowse", "getUserInfo"]),

    printReport: async function () {
      let { data } = await this.http
        .get(
          "api/v2/laporan/presensi-bulanan/" +
            this.record.bulan +
            "/" +
            this.record.pegawai_uuid
        )
        .then((res) => {
          var win = window.open("", "_blank");
          win.document.open();
          win.document.write(res.data);
          win.document.close();
          win.onafterprint = function () {
            win.close();
          };
        });
    },

    fetchRecord: async function () {
      let { data } = await this.http.get("api/user-info");

      this.record = data;

      this.foto = data.avatar;
    },
    fetchUpdate: async function () {
      try {
        let {
          data: { status, message, data },
        } = await this.http.post("api/update-profil", this.record);

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
        }

        this.snackbar.color = "green";
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.fetchRecord();
        this.getUserInfo();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      }
    },

    uploadFoto: function () {
      this.assignFileBrowse({
        fileType: [".jpg", ".jpeg", ".png"],
        query: {
          doctype: "avatar",
        },
        callback: (response) => {
          setTimeout(() => {
            this.foto = response.path;
            this.record.avatar = response.name;
          }, 1000);
        },
      });
    },

    fethcPegawais: async function () {
      try {
        let { data } = await this.http.get("api/v2/combo/pegawai");
        this.pegawais = data;
      } catch (error) {}
    },
  },
  watch: {
    "record.province_id": function () {
      this.fetchKabupatens();
    },
    "record.regency_id": function () {
      this.fetchKecamatans();
    },
    "record.district_id": function () {
      this.fetchDesa();
    },
    "reocrd.pendidikan_id": function () {
      this.fetchJurusan();
    },
  },
};
</script>
    
    <style></style>